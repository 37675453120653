import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import isEmailValidator from 'validator/lib/isEmail';

import {
  AuthButton,
  AuthCheckbox,
  AuthCheckboxContainer,
  AuthForm,
  AuthHelperContainer,
  AuthInput,
  AuthInputChildGroup,
  AuthInputLabel,
  AuthLabel,
  AuthSubTitle,
  AuthTitle,
  ErrorList,
  ErrorMessage,
} from '../auth.styles';
import { ReactComponent as MailOutlineIcon } from '@public/icons/MailOutline.svg';
import { ReactComponent as LockedComputerIcon } from '@public/icons/locked-computer.svg';
import { formErrorsToArray } from '@core';
import { ISignInPayload } from '@types';
import { useAppDispatch, useAppSelector } from 'store';
import { accountActions, accountSelectors } from 'store/account';
import { Loader } from 'components/Loader';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Mail is required')
    .test(value => isEmailValidator(value)),
  password: yup
    .string()
    .required('password is a required field')
    .test('len', 'Must be at least 6 characters', val => val.length >= 5),
});

export const Login: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInPayload>({ resolver: yupResolver(schema) });

  const singInLoading = useAppSelector(accountSelectors.singInLoadingSelector);
  const dispatch = useAppDispatch();

  const onSubmit = (data: ISignInPayload) => {
    dispatch(accountActions.signIn(data));
  };

  const formErrors = formErrorsToArray(errors);

  return (
    <AuthForm
      onSubmit={event => {
        void handleSubmit(onSubmit)(event);
      }}
    >
      {singInLoading && <Loader />}
      {!singInLoading && (
        <>
          <AuthTitle>გამარჯობა</AuthTitle>
          <AuthSubTitle>მხოლოდ ადმინისტრატორები</AuthSubTitle>
          <AuthInputChildGroup>
            <AuthInputLabel>
              <MailOutlineIcon />
            </AuthInputLabel>
            <AuthInput type="email" placeholder="მეილი" {...register('email')} />
          </AuthInputChildGroup>
          <AuthInputChildGroup>
            <AuthInputLabel>
              <LockedComputerIcon />
            </AuthInputLabel>
            <AuthInput type="password" placeholder="პაროლი" {...register('password')} />
          </AuthInputChildGroup>
          <AuthHelperContainer>
            <AuthCheckboxContainer>
              <AuthCheckbox type="checkbox" id="remember-me" />
              <AuthLabel htmlFor="remember-me">დაიმახსოვრე 30 დღე</AuthLabel>
            </AuthCheckboxContainer>
          </AuthHelperContainer>
          <ErrorList>
            {formErrors.length > 0 &&
              formErrors.map(error => <ErrorMessage key={error.key}>{error.value?.message}</ErrorMessage>)}
          </ErrorList>
          <AuthButton type="submit">შესვლა</AuthButton>
        </>
      )}
    </AuthForm>
  );
};
