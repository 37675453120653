import { catchError, concatMap, filter, from, switchMap } from 'rxjs';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { changeJWT, getUser, signIn } from 'core';
import { AppEpic } from 'store';
import { accountActions } from './account';
import { createSessionCookies, getToken, removeSessionCookies } from 'core/utils/tokenCookies';
import { paths } from 'router/paths';

export const singInEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(accountActions.signIn.match),
    switchMap(({ payload }) => {
      return from(signIn(payload)).pipe(
        concatMap(response => {
          const result = response.data;

          createSessionCookies({ token: result.token.accessToken });
          changeJWT(result.token.accessToken);

          return [
            accountActions.setUser({ user: result.account.user }),
            accountActions.setLoading(false),
            accountActions.setSingInLoading(false),
          ];
        }),
        catchError(() => {
          toast.error('მეილი/პაროლი არასწორია');
          return [accountActions.setLoading(false), accountActions.setSingInLoading(false)];
        }),
      );
    }),
  );

export const initEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(accountActions.init.match),
    switchMap(({}) => {
      const token = getToken();

      if (!token) {
        return [accountActions.setLoading(false)];
      }
      changeJWT(token);

      return [accountActions.setLoading(true), accountActions.fetchUser()];
    }),
  );

export const fetchUserEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(accountActions.fetchUser.match),
    switchMap(({}) => {
      return from(getUser()).pipe(
        concatMap(response => {
          const resp = response.data;
          return [accountActions.setUser({ user: resp.user }), accountActions.setLoading(false)];
        }),
        catchError(error => {
          const err = error as AxiosError;
          console.log(err);
          return [accountActions.setLoading(false)];
        }),
      );
    }),
  );

export const signOutEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(accountActions.signOut.match),
    switchMap(({}) => {
      removeSessionCookies();
      location.href = paths.SIGNIN_PATH;
      return [accountActions.setUser({})];
    }),
  );

export const accountEpics = [singInEpic, initEpic, signOutEpic, fetchUserEpic];
