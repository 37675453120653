import React from 'react';
import { PlacesType, Tooltip as ReactTooltip, VariantType } from 'react-tooltip';

interface TooltipProps {
  className: string;
  place?: PlacesType;
  children: JSX.Element;
  type?: VariantType;
}

const Tooltip: React.FC<TooltipProps> = ({ className, children, place, type }) => {
  return (
    <>
      <ReactTooltip variant={type} anchorSelect={className} place={place ?? 'top'}>
        {children}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
