import { Provider } from 'react-redux';

import { storeInstance } from 'store';

interface IProps {
  children: React.ReactNode;
}

const StateProvider: React.FC<IProps> = ({ children }: IProps) => {
  return <Provider store={storeInstance}>{children}</Provider>;
};

export const Layout: React.FC<IProps> = ({ children }: IProps) => <StateProvider>{children}</StateProvider>;
