export const setLocalStorageItem = (key: string, item: any) => {
  try {
    if (typeof item === 'object') {
      window.localStorage.setItem(key, JSON.stringify(item));
    } else {
      window.localStorage.setItem(key, item);
    }
  } catch (e) {}
};
export const getLocalStorageItem = (key: string): string | null => {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};
export const getLocalStorageJSONItem = <T = unknown>(key: string): T | null => {
  try {
    const item = window.localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item) as T;
    }
    return null;
  } catch (e) {
    return null;
  }
};
export const removeLocalStorageItem = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {}
};
export const clearLocalStorage = () => {
  try {
    window.localStorage.clear();
  } catch (e) {}
};
