import styles from './Home.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Overview from './Overview';
import PopularProducts from '../../components/PopularProducts';

const Home = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview className={styles.card} />
        </div>
        <div className={styles.col}>
          <PopularProducts className={styles.card} views="4" />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
