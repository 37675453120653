import { ReactNode, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useAppSelector } from 'store';
import { accountSelectors } from 'store/account';
import { ErrorState } from 'components/ErrorState';
import { Loader } from 'components/Loader';

type Props = {
  children: ReactNode;
};

function PublicRoute(props: Props) {
  const { children } = props;

  const user = useAppSelector(accountSelectors.userSelector);

  const isAuthenticated = user !== undefined;

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <ErrorBoundary fallback={<ErrorState text="An error occurred in the application." />}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default PublicRoute;
