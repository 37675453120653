import React, { useState } from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import styles from './Sidebar.module.sass';
import Icon from '../Icon';
import Theme from '../Theme';
import Dropdown from './Dropdown';
import Image from '../Image';
import { paths } from 'router/paths';

const navigation = [
  {
    title: 'მთავარი',
    icon: 'home',
    url: paths.ROOT_PATH,
  },
  {
    title: 'მომხმარებლები',
    icon: 'profile-circle',
    url: paths.USER_LIST_PATH,
  },
  {
    title: 'ივენთები',
    icon: 'diamond',
    url: paths.EVENTS_LIST_PATH,
  },
  {
    title: 'რეიტინგები',
    icon: 'promotion',
    url: paths.RANKING_LIST_PATH,
  },
  {
    title: 'შეტყობინებები',
    icon: 'notification',
    url: paths.NOTIFICATION_LIST_PATH,
  },
  {
    title: 'სტატიკური გვერდები',
    slug: 'income',
    icon: 'pie-chart',
    dropdown: [
      {
        title: 'წესები',
        url: '/page-editor/rules',
      },
      {
        title: 'სიმღერები',
        url: '/page-editor/songs',
      },
    ],
  },
];

interface SidebarProps {
  className?: string;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ className, onClose }) => {
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image className={styles.pic} src="/images/logo-dark.png" srcDark="/images/logo-light.png" alt="Core" />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={x.url}
                key={index}
                onClick={onClose}
              >
                <Icon name={x.icon} size="24" />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            ),
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <div className={cn(styles.overlay, { [styles.active]: visible })} onClick={() => setVisible(false)}></div>
    </>
  );
};

export default Sidebar;
