// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LIANCkcRJNYSKasT0gGX {
  color: #e70000;
  font-size: 12px;
}

.uPd38Xw0ph4_VZjVqji7 {
  display: none;
}

.R8PAWvmNDrtnsTDOebYt {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.f7BitPTxNgzbYhX4A3uK {
  width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/pages/events/modify/Ranks/Ranks.module.sass"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,eAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAIA;EACI,aAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;AADJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":["\n.error\n    color: #e70000\n    font-size: 12px\n\n\n.hidden\n    display: none\n\n\n\n.rankList\n    display: flex\n    flex-wrap: wrap\n    gap: 20px\n    margin-top: 20px\n.rankItem\n    width: 30%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `LIANCkcRJNYSKasT0gGX`,
	"hidden": `uPd38Xw0ph4_VZjVqji7`,
	"rankList": `R8PAWvmNDrtnsTDOebYt`,
	"rankItem": `f7BitPTxNgzbYhX4A3uK`
};
export default ___CSS_LOADER_EXPORT___;
