import React from 'react';
import cn from 'classnames';

import styles from './Table.module.sass';
import { Loader } from '../../../components/Loader';
import Row from './Row';

// data
import { IResponse, RankDto } from '@types';

interface TableProps {
  className?: string;
  isRequestLoading: boolean;
  list: IResponse<RankDto[]> | undefined;
  setActiveItem: (item: RankDto) => void;
  onDelete: (id: string) => void;
}

const Table: React.FC<TableProps> = ({ className, list, isRequestLoading, setActiveItem, onDelete }) => {
  return (
    <>
      {isRequestLoading && <Loader />}
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.row)}>
            <div className={styles.col}></div>
            <div className={styles.col}>სახელი</div>
            <div className={styles.col}>მინიმალური ქულა</div>
            <div className={styles.col}>მაქსიმალური ქულა</div>
            <div className={styles.col}></div>
          </div>

          {!isRequestLoading &&
            list?.data?.map(rank => (
              <Row onDelete={onDelete} item={rank} key={rank.id} setActiveItem={setActiveItem} />
            ))}
        </div>
      </div>
    </>
  );
};

export default Table;
