import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Details.module.sass';
import Icon from '../../../components/Icon';
import Head from './Head';
import { IUserListItem } from '@types';
import Parameter from './Parameter';

interface DetailsProps {
  className?: string;
  onClose: () => void;
  details: IUserListItem;
}

const Details: React.FC<DetailsProps> = ({ className, onClose, details }) => {
  return (
    <div className={cn(styles.details, className)}>
      <button className={styles.close} onClick={onClose}>
        <Icon name="close" size="20" />
      </button>
      <Head className={styles.head} details={details} />

      <div className={styles.group}>
        <Parameter label="ქულა" value={50} />
        <Parameter label="მეილი" value={details.email} />
        <Parameter label="ქალაქი" value={details.city} />
        <Parameter label="დაბადების თარიღი" value={details.birthday} />
        <Parameter label="ნომერი" value={details.phoneNumber} />
      </div>
    </div>
  );
};

export default Details;
