import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { FieldErrors, FieldValues } from 'react-hook-form';

function transform(transformKeyFunction: (string?: string | undefined) => string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function transformKeys(object: Record<string, any>): Record<string, any> {
    if (object instanceof Date) {
      return object;
    }
    if (Array.isArray(object)) {
      return object.map(transformKeys);
    }
    if (!object || typeof object !== 'object') {
      return object;
    }
    const keys = Object.keys(object);
    if (keys.length === 0) {
      return object;
    }
    return keys.reduce((acc: Record<string, unknown>, key) => {
      acc[transformKeyFunction(key)] = transformKeys(object[key]);
      return acc;
    }, {});
  };
}

export function keysToCamel(obj: Record<string, unknown>): Record<string, unknown> {
  return transform(camelCase)(obj);
}

export function keysToSnake(obj: Record<string, unknown>): Record<string, unknown> {
  return transform(snakeCase)(obj);
}

export const getErrorMessage = (errors: FieldErrors<FieldValues>, name: string) => {
  return errors[name]?.message;
};

export const getErrorMessageFromArrayIndex = (
  errors: FieldErrors<FieldValues>,
  name: string,
  subname: string,
  index: number,
) => {
  if (errors[name]?.[index]?.[subname]) {
    return errors[name][index][subname].message;
  }
  return '';
};

const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

export function isValidImageType(file: any) {
  if (file?.[0]?.name) {
    if (file.length === 0) {
      return false;
    }
    const fileName = file[0].name;
    return validFileExtensions.image.indexOf(fileName.split('.').pop()) > -1;
  } else if (typeof file === 'string') {
    return validFileExtensions.image.indexOf(file.split('.').pop()) > -1;
  }
}
