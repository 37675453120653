import { ReactNode, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useAppSelector } from 'store';
import { accountSelectors } from 'store/account';
import { Loader } from 'components/Loader';
import { ErrorState } from 'components/ErrorState';
import Page from 'components/Page';

type Props = {
  permissions?: string[];
  roles?: string[];
  redirectTo?: string;
};

function PrivateRoute(props: Props) {
  const { redirectTo = '/login' } = props;

  const user = useAppSelector(accountSelectors.userSelector);
  const userLoading = useAppSelector(accountSelectors.userLoadingSelector);

  const isAuthenticated = user !== undefined;

  //   const { hasAllPermissions } = validateUserPermissions({
  //     user,
  //     permissions,
  //     roles,
  //   });

  if (userLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  // if (!hasAllPermissions) {
  //   return <Navigate to="/" />;
  // }

  return (
    <ErrorBoundary fallback={<ErrorState text="An error occurred in the application." />}>
      <Suspense fallback={<Loader />}>
        <Page>
          <Outlet />
        </Page>
      </Suspense>
    </ErrorBoundary>
  );
}

export default PrivateRoute;
