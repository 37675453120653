import React from 'react';
import cn from 'classnames';

import styles from './Table.module.sass';
import { Loader } from 'components/Loader';
import Row from './Row';

// data
import { IEventTicketListItem, IPaginatedResponse } from '@types';

interface TableProps {
  className?: string;
  isRequestLoading: boolean;
  tickets: IPaginatedResponse<IEventTicketListItem> | undefined;
  onDecline: (id: string) => void;
  onApprove: (id: IEventTicketListItem) => void;
}

const Table: React.FC<TableProps> = ({ className, tickets, isRequestLoading, onApprove, onDecline }) => {
  return (
    <>
      {isRequestLoading && <Loader />}
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.row, styles.active)}>
            <div className={styles.col}></div>
            <div className={styles.col}>სახელი</div>
            <div className={styles.col}>ბილეთების რაოდენობა</div>
            <div className={styles.col}>ბოლო განახლების თარიღი</div>
            <div className={styles.col}>სტატუსი</div>
            <div className={styles.col}></div>
          </div>

          {!isRequestLoading &&
            tickets?.data?.items.map(ticket => (
              <Row item={ticket} key={ticket.id} onApprove={onApprove} onDecline={onDecline} />
            ))}
        </div>
      </div>
    </>
  );
};

export default Table;
