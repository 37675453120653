import { CreateNotificationPayload, IPaginatedResponse, NotificationListItem } from '@types';
import { httpClient } from '../http-client';

export const getNotificationList = async (): Promise<IPaginatedResponse<NotificationListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<NotificationListItem>>('bulk-notification', {
    params: {
      page: 1,
      limit: 10,
    },
  });
  return response.data;
};

export const createNotification = async (payload: CreateNotificationPayload): Promise<NotificationListItem> => {
  const response = await httpClient.post<NotificationListItem>('bulk-notification', payload);

  return response.data;
};
