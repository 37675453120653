import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import styles from './Ranks.module.sass';
import Card from 'components/Card';
import Switch from 'components/Switch';
import { getErrorMessageFromArrayIndex } from '@core';
import TextInput from 'components/TextInput';

const maxTicketNameKey = 'maxTicketNumber';
const arrayNameKey = 'ranksRestriction';

const Ranks = () => {
  const {
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const ticketsEnabled = getValues('ticketsEnabled') as boolean;

  const setTicketsEnabled = (value: boolean) => {
    setValue('ticketsEnabled', value, { shouldValidate: true });
  };

  const ranksList = getValues(arrayNameKey) as { rankId: string; name: string; maxTicketNumber: number }[];

  return (
    <Card
      className={cn(styles.card)}
      title="ბილეთები"
      classTitle="title-green"
      head={
        <div>
          <Switch
            className={styles.switch}
            value={ticketsEnabled ?? false}
            onChange={() => setTicketsEnabled(!ticketsEnabled)}
          />
        </div>
      }
    >
      <div className={ticketsEnabled ? styles.visible : styles.hidden}>
        <div>
          <div className={styles.rankList}>
            {ranksList?.map((rank, index) => {
              const name = `${arrayNameKey}.${index}.${maxTicketNameKey}`;
              return (
                <div key={rank.rankId} className={styles.rankItem}>
                  <TextInput
                    className={styles.field}
                    label={`სტატუსი - ${rank.name}`}
                    placeholder="ბილეთის რაოდენობა"
                    type="number"
                    name={name}
                    error={getErrorMessageFromArrayIndex(errors, arrayNameKey, maxTicketNameKey, index)}
                    register={register}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Ranks;
