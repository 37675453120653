import React, { useState } from 'react';
import cn from 'classnames';

import styles from './Table.module.sass';
import { Loader } from '../../../components/Loader';
import Row from './Row';

// data
import { IPaginatedResponse, IUserListItem } from '@types';

interface TableProps {
  className?: string;
  activeTable: boolean;
  isRequestLoading: boolean;
  users: IPaginatedResponse<IUserListItem> | undefined;
  setActiveId: (activeId: string) => void;
  activeId: string;
}

const Table: React.FC<TableProps> = ({ className, activeTable, users, isRequestLoading, activeId, setActiveId }) => {
  return (
    <>
      {isRequestLoading && <Loader />}
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.row, { [styles.active]: activeTable })}>
            <div className={styles.col}></div>
            <div className={styles.col}>სახელი</div>
            <div className={styles.col}>მეილი</div>
            <div className={styles.col}>ტელ. ნომერი</div>
          </div>

          {!isRequestLoading &&
            users?.data?.items.map(user => (
              <Row item={user} key={user.id} activeTable={activeTable} activeId={activeId} setActiveId={setActiveId} />
            ))}
        </div>
      </div>
    </>
  );
};

export default Table;
