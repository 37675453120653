import cn from 'classnames';

import styles from './Row.module.sass';
import { RankDto } from '@types';
import Icon from 'components/Icon';

interface RowProps {
  item: RankDto;
  setActiveItem: (active: RankDto) => void;
  onDelete: (id: string) => void;
}

const Row = ({ item, setActiveItem, onDelete }: RowProps) => {
  const handleClick = () => {
    setActiveItem(item);
  };

  const handleDelete = (e: Event) => {
    e.stopPropagation();
    onDelete(item.id);
  };
  return (
    <>
      <div className={cn(styles.row)} onClick={handleClick}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div>{item.name}</div>
        </div>

        <div className={styles.col}>
          <div>{item.minScore}</div>
        </div>
        <div className={styles.col}>
          <div>{item.maxScore}</div>
        </div>

        <div className={styles.col}>
          <button onClick={handleDelete} className={cn('button', 'button-small', 'button-stroke-red', styles.button)}>
            <Icon name="close" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Row;
