import SelectSearch, { SelectedOption, SelectedOptionValue, SelectSearchProps } from 'react-select-search';

import { filterUserByName } from '@core';

interface IProps {
  onChange: (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps,
  ) => void;
}

export const UserSelector: React.FC<IProps> = ({ onChange }) => {
  function renderUser(props, option, snapshot, className) {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10,
    };

    return (
      <button {...props} className={className} type="button">
        <span>
          <img alt="" style={imgStyle} width="28" height="28" src={option.profilePhoto} />
          <span>{option.name}</span>
        </span>
      </button>
    );
  }

  const getUserOptions = (query: string) => {
    return new Promise((resolve, reject) => {
      if (query.length < 3) {
        resolve([]);
        return;
      }
      filterUserByName(query)
        .then(resp => {
          resolve(
            resp.data.map(user => ({
              value: user.id,
              name: `${user.firstName} ${user.lastName}`,
              profilePhoto: user.profilePhoto,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              id: user.id,
              group: `${user.email} ${user.phoneNumber}`,
            })),
          );
        })
        .catch(reject);
    });
  };

  return (
    <SelectSearch
      options={[]}
      onChange={onChange}
      renderOption={renderUser}
      getOptions={getUserOptions}
      search
      placeholder="აირჩიეთ"
    />
  );
};
