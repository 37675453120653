import { CaseReducer, PayloadAction, createSlice, createAction } from '@reduxjs/toolkit';

import { ISignInPayload, IUser, AccountState } from '@types';

const name = 'account';
const initialState = {
  userLoading: true,
  signInLoading: false,
} as AccountState;

const init = createAction(`${name}/init`);
const fetchUser = createAction(`${name}/fetchUser`);
const signIn = createAction<ISignInPayload>(`${name}/signIn`);
const signOut = createAction(`${name}/signOut`);

const setUser: CaseReducer<AccountState, PayloadAction<{ user?: IUser }>> = (state, { payload }) => {
  state.user = payload.user;
};

const setLoading: CaseReducer<AccountState, PayloadAction<boolean>> = (state, { payload }) => {
  state.userLoading = payload;
};

const setSingInLoading: CaseReducer<AccountState, PayloadAction<boolean>> = (state, { payload }) => {
  state.signInLoading = payload;
};

const handleSignIn: CaseReducer<AccountState, PayloadAction<ISignInPayload>> = (state, {}) => {
  state.signInLoading = true;
};

export const accountSlice = createSlice({
  name,
  initialState,
  reducers: {
    setUser,
    setLoading,
    handleSignIn,
    setSingInLoading,
  },
  extraReducers: builder => {
    builder.addCase(signIn, (state, action) => {
      handleSignIn(state, action);
    });
  },
});

export const accountFeature = name;
export const accountActions = {
  ...accountSlice.actions,
  signIn,
  init,
  signOut,
  fetchUser,
};
