import React from 'react';
import { APIProvider, ControlPosition, Map, MapControl } from '@vis.gl/react-google-maps';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import DatePicker from 'react-datepicker';

import styles from './CheckInDetails.module.sass';
import { UndoRedoControl, useDrawingManager } from 'components/map';
import { getErrorMessage } from '@core';
import Card from 'components/Card';
import TextInput from 'components/TextInput';
import Switch from 'components/Switch';

const LocationContent: React.FC<{ defaultCoordinates: string | null }> = ({ defaultCoordinates }) => {
  const drawingManager = useDrawingManager();

  const {
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const handleLocationUpdate = (polygon: string) => {
    // console.log(polygon, 'polygon');
    setValue('checkinMapPolygon', polygon, { shouldValidate: true });
  };

  const updateDate = (date: Date, name: string) => {
    setValue(name, date, { shouldValidate: true });
  };

  const checkInEnabled = getValues('checkInEnabled') as boolean;

  const setCheckInEnabled = (value: boolean) => {
    setValue('checkInEnabled', value, { shouldValidate: true });
  };

  return (
    <Card
      className={cn(styles.card)}
      title="ჩექინი"
      classTitle="title-green"
      head={
        <div>
          <Switch
            className={styles.switch}
            value={checkInEnabled ?? false}
            onChange={() => setCheckInEnabled(!checkInEnabled)}
          />
        </div>
      }
    >
      <div className={checkInEnabled ? styles.visible : styles.hidden}>
        <div className={cn(styles.wrapper)}>
          <div className={cn(styles.date)}>
            <div className={cn(styles.label)}>
              დაჩექინკების დაწყება
              {getErrorMessage(errors, 'checkInStartDate') && (
                <span className={cn(styles.error)}>- {getErrorMessage(errors, 'checkInStartDate')}</span>
              )}
            </div>
            <DatePicker
              selected={getValues('checkInStartDate')}
              onChange={date => updateDate(date, 'checkInStartDate')}
              showTimeSelect
              locale="ka"
              timeFormat="HH:mm"
              dateFormat={'dd/M/yyyy HH:mm'}
            />
          </div>

          <div className={cn(styles.date)}>
            <div className={cn(styles.label)}>
              დაჩექინკების დასრულება
              {getErrorMessage(errors, 'checkInEndDate') && (
                <span className={cn(styles.error)}>- {getErrorMessage(errors, 'checkInEndDate')}</span>
              )}
            </div>
            <DatePicker
              selected={getValues('checkInEndDate')}
              onChange={date => updateDate(date, 'checkInEndDate')}
              showTimeSelect
              locale="ka"
              timeFormat="HH:mm"
              dateFormat={'dd/M/yyyy HH:mm'}
            />
          </div>
        </div>

        <br />

        {getErrorMessage(errors, 'checkinMapPolygon') && (
          <div className={styles.error}>{getErrorMessage(errors, 'checkinMapPolygon')}</div>
        )}

        <Map
          defaultZoom={16}
          style={{ height: '600px', width: '100%' }}
          defaultCenter={{ lat: 41.723018, lng: 44.789877 }}
          gestureHandling={'greedy'}
          disableDefaultUI
        />

        <MapControl position={ControlPosition.TOP_CENTER}>
          <UndoRedoControl
            savedCoordinates={defaultCoordinates}
            locationUpdated={handleLocationUpdate}
            drawingManager={drawingManager}
          />
        </MapControl>
      </div>
    </Card>
  );
};

const CheckInDetails: React.FC<{ defaultCoordinates: string | null }> = ({ defaultCoordinates }) => {
  const API_KEY = process.env.GOOGLE_MAPS_API_KEY as string;

  return (
    <APIProvider apiKey={API_KEY}>
      <LocationContent defaultCoordinates={defaultCoordinates} />
    </APIProvider>
  );
};

export default CheckInDetails;
