import React, { useMemo, useState } from 'react';
import cn from 'classnames';

import { useAsync } from 'react-async';

import styles from './CustomerList.module.sass';
import Card from '../../components/Card';
import Form from '../../components/Form';
import Filters from '../../components/Filters';
import Settings from './Settings';
import Table from './Table';
import Details from './Details';
import { getUserList } from 'core/services/komagebi/users';

const UserListPage = () => {
  const { data: usersList, isLoading: isUserListLoading } = useAsync(getUserList);

  const [search, setSearch] = useState('');
  const [activeId, setActiveId] = useState('');

  const selectedUser = useMemo(() => {
    return usersList?.data?.items.find(x => x.id === activeId);
  }, [activeId]);

  const visible = activeId !== '';

  return (
    <>
      <Card
        className={styles.card}
        title="სია"
        classTitle={cn('title-purple', styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => {}}
              placeholder="Search by name or email"
              type="text"
              name="search"
              icon="search"
            />

            <Filters className={styles.filters} title="Showing 10 of 24 customer">
              <Settings />
            </Filters>
          </>
        }
      >
        <div className={cn(styles.row, { [styles.flex]: visible })}>
          <Table
            users={usersList}
            isRequestLoading={isUserListLoading}
            className={styles.table}
            activeTable={visible}
            activeId={activeId}
            setActiveId={setActiveId}
          />
          {selectedUser && (
            <Details className={styles.details} details={selectedUser} onClose={() => setActiveId('')} />
          )}
        </div>
      </Card>
    </>
  );
};

export default UserListPage;
