import { createRoot } from 'react-dom/client';

import { setUpHttpClientConfig } from '@core';
import { App } from './App';
import { Layout } from './layout/core.layout';

setUpHttpClientConfig({
  baseUrl: process.env.API_URL ?? '',
  urlsWithoutJWT: ['/token'],
});

const el = document.getElementById('root')!;
const root = createRoot(el);
root.render(
  <Layout>
    <App />
  </Layout>,
);
