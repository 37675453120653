import styles from './Parameter.module.sass';

const Parameter = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <div className={styles.parameter}>
      <div className={styles.title}>{label}</div>
      {<div className={styles.content}>{value}</div>}
    </div>
  );
};

export default Parameter;
