import cn from 'classnames';

import styles from './Row.module.sass';
import { IUserListItem } from '@types';

interface RowProps {
  item: IUserListItem;
  activeTable: boolean;
  activeId: string;
  setActiveId: (active: string) => void;
}

const Row = ({ item, activeTable, activeId, setActiveId }: RowProps) => {
  const handleClick = (id: string) => {
    console.log(id, item, 'id');
    setActiveId(id);
  };

  return (
    <>
      <div className={cn(styles.row, { [styles.selected]: activeId === item.id }, { [styles.active]: activeTable })}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div className={styles.item} onClick={() => handleClick(item.id)}>
            <div className={styles.avatar}>
              <img
                src={item.profilePhoto !== '' ? item.profilePhoto : '/images/content/avatar-2.jpg'}
                alt="Profile Photo"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{`${item.firstName} ${item.lastName}`}</div>
              <div className={styles.city}>{item.city}</div>
              <div className={styles.email}>{item.email}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.email}>{item.email}</div>
        </div>
        <div className={styles.col}>
          <div>{item.phoneNumber}</div>
        </div>
      </div>
    </>
  );
};

export default Row;
