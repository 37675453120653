import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import styles from './modify.module.sass';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import { IEventTicketListItem, RankBaseDto } from '@types';
import { approveTicket, getErrorMessage } from '@core';
import { OverlayLoader } from 'components/Loader';

const schema = yup.object().shape({
  quantity: yup.number().min(0).required('სავალდებულოა'),
});

const ApproveTicket: React.FC<{
  ticket: IEventTicketListItem | undefined;
  eventId: string;
  success: () => void;
}> = ({ eventId, ticket, success }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ quantity: number; ticketId: string }>({ resolver: yupResolver(schema) });

  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (ticket?.quantity) {
      setValue('quantity', ticket.quantity);
      setValue('ticketId', ticket.id);
    }
  }, [ticket, setValue]);

  const onSubmit = () => {
    void handleSubmit(
      data => {
        setIsAdding(true);

        approveTicket(eventId, data)
          .then(() => {
            toast.success('დადასტურდა');
            success();
          })
          .catch((err: AxiosError) => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setIsAdding(false);
          });
      },
      (err, event) => {
        console.log(err, event, 'validation errors');
      },
    )();
  };

  return (
    <div className={styles.post}>
      {isAdding && <OverlayLoader />}
      <div className={cn('title-green', styles.title)}>ბილეთის დადასტურება</div>

      <div className={styles.field}>
        <TextInput
          className={styles.field}
          label="რაოდენობა"
          name="quantity"
          error={getErrorMessage(errors, 'quantity')}
          type="number"
          register={register}
        />
      </div>

      <div className={styles.foot}>
        <button onClick={onSubmit} className={cn('button', styles.button)}>
          <span>დადასტურება</span>
          <Icon name="arrow-right" size="24" />
        </button>
      </div>
    </div>
  );
};

export default ApproveTicket;
