import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';

type ReturnValueType =
  | FieldError
  | (Record<
      string,
      Partial<{
        type: string | number;
        message: string;
      }>
    > &
      Partial<{
        type: string | number;
        message: string;
      }>)
  | undefined;

export function formErrorsToArray<T extends FieldValues>(
  errors: FieldErrors<T>,
): {
  key: string;
  value: ReturnValueType;
}[] {
  const formErrors: {
    key: string;
    value: ReturnValueType;
  }[] = [];

  const keys = Object.keys(errors) as (keyof typeof errors)[];
  keys.forEach(key => {
    formErrors.push({
      key: key as string,
      value: errors[key] as FieldError,
    });
  });
  return formErrors;
}
