import { IResponse } from '@types';
import { httpClient } from '../http-client';
import { RankBaseDto, RankDto } from 'types/ranking';

export const getRankingList = async (): Promise<IResponse<RankDto[]>> => {
  const response = await httpClient.get<IResponse<RankDto[]>>('ranking/list');
  return response.data;
};

export const deleteRank = async (id: string): Promise<IResponse<{ success: boolean }>> => {
  const response = await httpClient.delete<IResponse<{ success: boolean }>>(`ranking/${id}`);

  return response.data;
};

export const createRank = async (payload: RankBaseDto): Promise<RankDto> => {
  const response = await httpClient.post<RankDto>('ranking/create', payload);

  return response.data;
};

export const updateRank = async (id: string, payload: RankBaseDto): Promise<RankDto> => {
  const response = await httpClient.put<RankDto>(`ranking/${id}`, payload);

  return response.data;
};
