import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './translations/en.json';
import ar from './translations/ar.json';
import ka from './translations/ka.json';

/* eslint-disable @typescript-eslint/no-floating-promises */
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
      ka: {
        translation: ka,
      },
    },
    fallbackLng: 'ka',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
