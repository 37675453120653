export const products = [
  {
    id: 0,
    product: 'Bento Matte 3D Illustration',
    link: 'ui8.net/product/product-link',
    image: '/images/content/product-pic-1.jpg',
    image2x: '/images/content/product-pic-1@2x.jpg',
    price: 98,
    date: 'Apr 3, 2021 at 3:55 PM',
    ratingValue: 4.8,
    ratingCounter: 87,
  },
  {
    id: 1,
    product: 'Node - Crypto iOS UI design kit',
    link: 'ui8.net/product/product-link',
    image: '/images/content/product-pic-2.jpg',
    image2x: '/images/content/product-pic-2@2x.jpg',
    price: 123,
    date: 'Apr 6, 2021 at 6:55 PM',
    ratingValue: 4.9,
    ratingCounter: 123,
  },
  {
    id: 2,
    product: 'TaskEz: Productivity App iOS UI Kit',
    link: 'ui8.net/product/product-link',
    image: '/images/content/product-pic-3.jpg',
    image2x: '/images/content/product-pic-3@2x.jpg',
    price: 0,
    date: 'Apr 10, 2021 at 10:55 PM',
  },
  {
    id: 3,
    product: 'Fleet - Travel shopping UI design kit',
    link: 'ui8.net/product/product-link',
    image: '/images/content/product-pic-4.jpg',
    image2x: '/images/content/product-pic-4@2x.jpg',
    price: 43,
    date: 'Apr 12, 2021 at 12:55 PM',
    ratingValue: 4.6,
    ratingCounter: 12,
  },
  {
    id: 4,
    product: 'Academe 3D Education Icons',
    link: 'ui8.net/product/product-link',
    image: '/images/content/product-pic-5.jpg',
    image2x: '/images/content/product-pic-5@2x.jpg',
    price: 8,
    date: 'Apr 19, 2021 at 19:55 PM',
    ratingValue: 3.2,
    ratingCounter: 159,
  },
  {
    id: 5,
    product: 'Fleet - Travel shopping UI design kit',
    link: 'ui8.net/product/product-link',
    image: '/images/content/product-pic-6.jpg',
    image2x: '/images/content/product-pic-6@2x.jpg',
    price: 198,
    date: 'Apr 23, 2021 at 23:55 PM',
    ratingValue: 4.4,
    ratingCounter: 22,
  },
];
