import { IPaginatedResponse, IResponse, IUserListItem } from '@types';
import { httpClient } from '../http-client';

export const getUserList = async (): Promise<IPaginatedResponse<IUserListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IUserListItem>>('user/list', {
    params: {
      page: 1,
      limit: 10,
    },
  });
  return response.data;
};

export const filterUserByName = async (query: string): Promise<IResponse<IUserListItem[]>> => {
  const response = await httpClient.get<IResponse<IUserListItem[]>>('user/filter', {
    params: {
      query: query,
    },
  });
  return response.data;
};
