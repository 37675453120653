import { AuthContainer } from './auth.styles';
import { Login } from './login';

const Auth = () => {
  return (
    <AuthContainer>
      <Login />
    </AuthContainer>
  );
};
export default Auth;
