import { filter, switchMap } from 'rxjs';

import { uiActions } from './ui.slice';
import { AppEpic } from 'store';

import i18n from '../../i18n';
import { Locale, UiSpinnerFeatureKey } from '@core';

const getLanguage = (url: URL): Locale => {
  const lang = url.searchParams.get('locale') as Locale;

  if (lang && ['ar', 'ka'].includes(lang)) {
    return lang;
  }
  return Locale.En;
};

export const setFeatureSpinnerEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(uiActions.setGlobalLoader.match),
    switchMap(({ payload }) => {
      return [
        uiActions.setFeatureSpinnerStateStore({
          feature: UiSpinnerFeatureKey.Global,
          state: payload,
        }),
      ];
    }),
  );

export const changeLanguageEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(uiActions.changeLanguage.match),
    switchMap(({ payload }) => {
      const language = payload;

      void i18n.changeLanguage(language);

      return [
        uiActions.changeLanguageStateStore({
          lang: language,
        }),
      ];
    }),
  );

export const initEpic: AppEpic = actions$ =>
  actions$.pipe(
    filter(uiActions.init.match),
    switchMap(({}) => {
      const url = new URL(location.href);

      const currentLanguage = getLanguage(url);

      return [uiActions.changeLanguage(currentLanguage)];
    }),
  );

export const uiEpics = [setFeatureSpinnerEpic, changeLanguageEpic, initEpic];
