import React from 'react';
import cn from 'classnames';

import styles from './Table.module.sass';
import { Loader } from '../../../../components/Loader';
import Row from './Row';

// data
import { IEventListItem, IPaginatedResponse } from '@types';

interface TableProps {
  className?: string;
  isRequestLoading: boolean;
  list: IPaginatedResponse<IEventListItem> | undefined;
  onDelete: (id: string) => void;
}

const Table: React.FC<TableProps> = ({ className, list, isRequestLoading, onDelete }) => {
  return (
    <>
      {isRequestLoading && <Loader />}
      <div className={cn(styles.wrapper, className)}>
        <div className={cn(styles.table)}>
          <div className={cn(styles.row)}>
            <div className={styles.col}></div>
            <div className={styles.col}>სახელი</div>
            <div className={styles.col}>თარიღი</div>
            <div className={styles.col}></div>
          </div>

          {!isRequestLoading && list?.data?.items.map(event => <Row onDelete={onDelete} item={event} key={event.id} />)}
        </div>
      </div>
    </>
  );
};

export default Table;
