import SelectSearch, { SelectedOption, SelectedOptionValue, SelectSearchProps } from 'react-select-search';

import { useAsync } from 'react-async';
import { useMemo } from 'react';

import { getRankingList } from '@core';

interface IProps {
  onChange: (
    selectedValue: SelectedOptionValue | SelectedOptionValue[],
    selectedOption: SelectedOption | SelectedOption[],
    optionSnapshot: SelectSearchProps,
  ) => void;
}

export const RankSelector: React.FC<IProps> = ({ onChange }) => {
  const { data: ranks, isLoading } = useAsync(getRankingList);

  const mappedRank = useMemo(() => {
    if (!ranks?.data) {
      return [];
    }

    return ranks.data.map(rank => ({
      value: rank.id,
      id: rank.id,
      name: rank.name,
    }));
  }, [ranks]);

  return !isLoading && <SelectSearch options={mappedRank} multiple onChange={onChange} placeholder="აირჩიეთ" />;
};
