import { IResponse } from '@types';
import { httpClient } from '../http-client';

export const updatePage = async (
  name: string,
  payload: { content: string },
): Promise<IResponse<{ content: string; name: string }>> => {
  const response = await httpClient.put<IResponse<{ content: string; name: string }>>(`page/${name}`, payload);

  return response.data;
};

export const getPageContent = async (name: string): Promise<IResponse<{ content: string; name: string }>> => {
  const response = await httpClient.get<IResponse<{ content: string; name: string }>>(`page/${name}`);
  return response.data;
};
