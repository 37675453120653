import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Head.module.sass';
import Icon from '../../../../components/Icon';
import { IUserListItem } from '@types';

type HeadProps = {
  className?: string;
  details: IUserListItem;
};

const Head: React.FC<HeadProps> = ({ className, details }) => {
  return (
    <div className={cn(styles.head, className)}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <img src="/images/content/avatar-1.jpg" alt="Avatar" />
        </div>
        <div className={styles.details}>
          <div className={styles.man}>
            {details.firstName} {details.lastName}
          </div>
          <div className={styles.login}>{details.email}</div>
        </div>
      </div>
      <div className={styles.btns}>
        <button className={cn('button-stroke', styles.button)}>
          <span>Edit</span>
          <Icon name="edit" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Head;
