import { css, styled } from 'styled-components';

export const AuthContainer = styled.div`
  max-width: 430px;
  margin: 50px auto;
  background: #fff;
  padding: 32px 24px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .dna-wrapper {
    align-self: center;
  }
`;
export const AuthTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
`;
export const AuthSubTitle = styled.h4`
  color: #68738d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;
`;
export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
`;
export const AuthInputChildGroup = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

export const AuthInputGroup = styled.div`
  display: flex;
  gap: 12px;
`;

export const AuthInputLabel = styled.div`
  position: absolute;
  top: 6px;
  left: 12px;
`;

export const AuthInput = styled.input(
  () => css`
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #e5e8ec;
    font-size: 12px;
    font-weight: 400;
    padding-left: 40px;
  `,
);
export const AuthHelperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AuthCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const AuthCheckbox = styled.input`
  margin-right: 4px;
`;
export const AuthLabel = styled.label`
  color: #68738d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;
export const AuthButton = styled.button`
  padding: 12px;
  border-radius: 12px;
  color: white;
  background-color: #2a85ff;
  cursor: pointer;
  margin-bottom: 10px;
  border: 1px solid transparent;
  font-weight: 700;
`;

export const AuthSocialIcon = styled(AuthButton)`
  background-color: transparent;
  border: 1px solid #e5e8ec;
  font-size: 17px;
  font-weight: 400;
  color: #68738d;
  gap: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const AuthDivider = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: #68738d;
  display: flex;
  gap: 15px;
  align-items: center;

  &::before,
  &::after {
    content: '';
    width: 100%;
    background-color: #e5e8ec;
    height: 1px;
  }
`;

export const AuthLink = styled.a`
  color: #316aff;
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;

export const ErrorList = styled.div``;
export const ErrorMessage = styled.div`
  color: red;
`;
