import { ISignInPayload, IAuthUserResponse, IResponse, AuthUserResponse } from '@types';
import { httpClient } from '../http-client';

export const signIn = async (payload: ISignInPayload): Promise<IResponse<IAuthUserResponse>> => {
  const response = await httpClient.post<IResponse<IAuthUserResponse>>('auth/signin', payload);

  return response.data;
};

export const getUser = async (): Promise<IResponse<AuthUserResponse>> => {
  const response = await httpClient.get<IResponse<AuthUserResponse>>('user/me');
  return response.data;
};
