import { createSelector } from '@reduxjs/toolkit';

import { RootState, AccountState } from 'types';
import { accountFeature } from './account';

export const accountSelector = (state: RootState): AccountState => state[accountFeature];

export const userSelector = createSelector(accountSelector, state => state.user);
export const userLoadingSelector = createSelector(accountSelector, state => state.userLoading);
export const singInLoadingSelector = createSelector(accountSelector, state => state.signInLoading);
