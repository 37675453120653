import React from 'react';
import cn from 'classnames';
import { DNA, ThreeCircles } from 'react-loader-spinner';

import styles from './Loader.module.sass';

export const Loader = () => {
  return (
    <div className={cn(styles.wrapper)}>
      <DNA visible height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} />
    </div>
  );
};

export const OverlayLoader = () => {
  return (
    <div className={cn(styles.overlay)}>
      <ThreeCircles
        height="100"
        width="100"
        color="#fc0000"
        ariaLabel="three-circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
