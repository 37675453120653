import React from 'react';
import cn from 'classnames';

import styles from './Panel.module.sass';
type IProps = {
  onSubmit: () => void;
};

const Panel: React.FC<IProps> = ({ onSubmit }) => {
  return (
    <div className={cn('panel', styles.panel)}>
      <div className={styles.info}></div>
      <div className={styles.btns}>
        <button onClick={onSubmit} className={cn('button', styles.button)}>
          შენახვა
        </button>
      </div>
    </div>
  );
};

export default Panel;
