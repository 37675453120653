import { Action, combineSlices, configureStore, Dispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';

import { uiEpics, uiSlice } from './ui';
import { accountEpics, accountSlice } from './account';

const reducer = combineSlices(uiSlice, accountSlice);

export type RootState = ReturnType<typeof reducer>;
export type AppEpic = Epic<unknown, unknown, RootState>;

const epicMiddleware = createEpicMiddleware<unknown, unknown, RootState>();

const rootEpic = combineEpics(...uiEpics, ...accountEpics);

type AppDispatch = Dispatch<Action>;

export const storeInstance = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(epicMiddleware),
});

epicMiddleware.run(rootEpic);

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
