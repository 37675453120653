import React, { useState } from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import styles from './Header.module.sass';
import Icon from '../Icon';
import Search from './Search';
import User from './User';

const Header = ({ onOpen }: { onOpen: () => void }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      <Search className={cn(styles.search, { [styles.visible]: visible })} />
      <button className={cn(styles.buttonSearch, { [styles.active]: visible })} onClick={() => setVisible(!visible)}>
        <Icon name="search" size="24" />
      </button>
      <div className={styles.control} onClick={() => setVisible(false)}>
        <User className={styles.user} />
      </div>
    </header>
  );
};

export default Header;
