import { useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { useAsync } from 'react-async';

import styles from './CustomerList.module.sass';
import Card from '../../components/Card';
import Table from './Table';
import { deleteRank, getRankingList } from 'core/services/komagebi/ranking';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModifyRank from './modify';
import { RankDto } from '@types';

const RankingPage = () => {
  const { data: rankingList, isLoading: isRankingListLoading, reload } = useAsync(getRankingList);

  const [selectedRank, setSelectedRank] = useState<RankDto | undefined>();
  const [visibleModal, setVisibleModal] = useState(false);

  const handleDelete = async (id: string) => {
    const userConfirmed = window.confirm('ნამდვილად გსურთ წაშლა?');
    if (!userConfirmed) {
      return;
    }
    try {
      const result = await deleteRank(id);
      if (result.data.success) {
        toast.info('ჩანაწერი წაიშალა');
        reload();
        return;
      }
    } catch {
      toast.error('დაფიქსირდა შეცდომა, თავიდან სცადეთ');
    }
  };

  const resetFields = () => {
    setSelectedRank(undefined);
  };
  const onUpdate = () => {
    resetFields();
    setVisibleModal(false);
    reload();
  };

  const handleActiveItemClick = (item: RankDto) => {
    setSelectedRank(item);
    setVisibleModal(true);
  };

  const onAdd = () => {
    resetFields();
    setVisibleModal(true);
  };

  return (
    <>
      <Modal outerClassName={styles.outer} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ModifyRank item={selectedRank} updated={onUpdate} />
      </Modal>
      <Card
        className={styles.card}
        title="სია"
        classTitle={cn('title-purple', styles.title)}
        classCardHead={cn(styles.head)}
        head={
          <>
            <div onClick={onAdd} className={cn('button', 'button-small', styles.button)}>
              <Icon name="add" size="24" />
              <span>დამატება</span>
            </div>
          </>
        }
      >
        <div className={cn(styles.row)}>
          <Table
            list={rankingList}
            isRequestLoading={isRankingListLoading}
            className={styles.table}
            setActiveItem={handleActiveItemClick}
            onDelete={id => {
              void handleDelete(id);
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default RankingPage;
