import { useCallback, useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { declineTicket, getEvenTicketList } from '@core';
import Card from 'components/Card';
import Form from 'components/Form';
import styles from './TicketRequestList.module.sass';
import Table from './Table';
import Modal from 'components/Modal';
import ApproveTicket from './ApproveTicket';
import { IEventTicketListItem } from '@types';

export const TicketRequestList = () => {
  const params = useParams();

  const [selectedTicket, setSelectedTicket] = useState<IEventTicketListItem | undefined>();
  const [visibleModal, setVisibleModal] = useState(false);

  const {
    data: usersList,
    isLoading: isUserListLoading,
    reload,
  } = useAsync({
    promiseFn: getEvenTicketList,
    id: params.id,
  });
  const [search, setSearch] = useState('');

  const handleDecline = useCallback(
    async (ticketId: string) => {
      try {
        const eventId = params.id;
        if (!eventId) {
          return;
        }
        const result = await declineTicket(eventId, ticketId);
        if (result.data) {
          toast.info('ჩანაწერი უარყოფილია');
          reload();
          return;
        }
      } catch {
        toast.error('დაფიქსირდა შეცდომა, თავიდან სცადეთ');
      }
    },
    [params.id],
  );

  const onApproveSuccess = () => {
    setSelectedTicket(undefined);
    setVisibleModal(false);
    reload();
  };

  const handleApproveClickClick = (item: IEventTicketListItem) => {
    setSelectedTicket(item);
    setVisibleModal(true);
  };

  const eventId = params.id || '';

  return (
    <>
      <Modal outerClassName={styles.outer} visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <ApproveTicket ticket={selectedTicket} eventId={eventId} success={onApproveSuccess} />
      </Modal>
      <Card className={styles.card} classTitle={cn('title-purple', styles.title)}>
        <div className={styles.search}>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onSubmit={() => {}}
            placeholder="ძებნა"
            type="text"
            name="search"
            icon="search"
          />
        </div>
        <div className={cn(styles.row, { [styles.flex]: true })}>
          <Table
            tickets={usersList}
            isRequestLoading={isUserListLoading}
            onDecline={$event => void handleDecline($event)}
            onApprove={item => handleApproveClickClick(item)}
            className={styles.table}
          />
        </div>
      </Card>
    </>
  );
};
