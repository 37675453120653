import React, { useState } from 'react';
import cn from 'classnames';

import styles from './List.module.sass';
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import Item from './Item';

// data
import { useAsync } from 'react-async';
import { getNotificationList } from 'core/services/komagebi/notification';
import { Loader } from 'components/Loader';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
import { paths } from 'router/paths';

const intervals = ['ბოლო'];

const List = ({ className }) => {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState(intervals[0]);

  const { data: notifications, isLoading, reload } = useAsync(getNotificationList);

  const onAdd = () => {
    navigate(paths.NOTIFICATION_ADD_PATH);
  };
  return (
    <Card
      className={cn(styles.card, className)}
      title="შეტყობინებები"
      classTitle={cn('title-red', styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
          <div onClick={onAdd} className={cn('button', 'button-small', styles.button)}>
            <Icon name="add" size="24" />
            <span>დამატება</span>
          </div>
        </>
      }
    >
      <div className={styles.notifications}>
        <div className={styles.list}>
          {isLoading && <Loader />}
          {notifications &&
            notifications.data.items.map((x, index) => (
              <Item className={cn(styles.item, className)} item={x} key={index} />
            ))}
        </div>
      </div>
    </Card>
  );
};

export default List;
