import cn from 'classnames';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor as ReactEditor } from 'react-draft-wysiwyg-editor';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg-editor/dist/react-draft-wysiwyg.css';

import styles from './Editor.module.sass';
import Tooltip from '../Tooltip';
import { SetStateAction, useEffect, useState } from 'react';

interface EditorProps {
  state?: EditorState | undefined;
  onChange?: (value: string) => void;
  classEditor?: string;
  label?: string;
  classLabel?: string;
  tooltip?: string;
  place?: string;
  button?: string;
  defaultValue: string;
  error?: string;
}

const Editor = ({
  onChange,
  classEditor,
  label,
  classLabel,
  tooltip,
  place,
  button,
  error,
  defaultValue,
}: EditorProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (defaultValue) {
      // const content = htmlToDraf(JSON.parse(defaultValue));
      // const state = EditorState.createWithContent(convertToRaw(content));
      // setEditorState(state);
      const blocksFromHtml = htmlToDraft(defaultValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [defaultValue]);

  const stateToHTML = (state: SetStateAction<EditorState>) => {
    if (state.getCurrentContent().hasText()) {
      return draftToHtml(convertToRaw(state.getCurrentContent()));
    }
    return '';
  };

  const onEditorStateChange = (editorState: SetStateAction<EditorState>) => {
    const content = stateToHTML(editorState);
    onChange?.(content);
    setEditorState(editorState);
  };

  return (
    <div className={cn(styles.editor, { [styles.editorButton]: button }, classEditor)}>
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{' '}
          {tooltip && (
            <Tooltip className={styles.tooltip} title={tooltip} icon="info" place={place ? place : 'right'} />
          )}
          {error && <div className={styles.error}>- {error}</div>}
        </div>
      )}
      <ReactEditor
        editorState={editorState}
        toolbarClassName={styles.editorToolbar}
        wrapperClassName={styles.editorWrapper}
        editorClassName={styles.editorMain}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'embedded',
            'emoji',
            'image',
            'remove',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered'],
          },
          textAlign: {
            options: ['center'],
          },
        }}
      />
      {button && <button className={cn('button-small', styles.button)}>{button}</button>}
    </div>
  );
};

export default Editor;
