import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './File.module.sass';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type AllowedFiles = 'image' | '*';

interface FileProps {
  className: string;
  label: string;
  title: string;
  type?: AllowedFiles;
  register?: UseFormRegister<FieldValues>;
  error?: string;
  name: string;
  value: string;
}

const getAcceptByAllowedFiles = (type: AllowedFiles) => {
  switch (type) {
    case 'image':
      return 'image/*';
    default:
      return '*';
  }
};

const File = ({ className, label, error, title, register, type = '*', name, value }: FileProps) => {
  const [file, setFile] = useState<string | undefined>();

  useEffect(() => {
    setFile(value);
  }, [value]);

  const showImagePreview = file && type === 'image';

  return (
    <div className={cn(styles.file, className)}>
      {label && (
        <div className={styles.label}>
          {label}
          {error && <div className={styles.error}>- {error}</div>}
        </div>
      )}

      <div className={styles.wrap}>
        <input
          className={styles.input}
          name={name}
          accept={getAcceptByAllowedFiles(type)}
          type="file"
          {...(register && register(name))}
        />
        <div className={styles.box}>
          <Icon name="upload" size="24" />
          {title}
        </div>
      </div>

      {showImagePreview && <img className={styles.preview} src={file} alt="Preview" />}
    </div>
  );
};

export default File;
