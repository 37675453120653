import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Outlet } from 'react-router-dom';

import styles from './Page.module.sass';
import Sidebar from '../Sidebar';
import Header from '../Header';

interface IState {
  wide?: string;
}

const Page: FC<IState> = ({ wide }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, {
            [styles.visible]: visible,
          })}
          onClose={() => setVisible(false)}
        />
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
