// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NMX9JEbdSQjpCIXekFjF {
  color: #e70000;
  font-size: 12px;
}

.DP5rDw7Vkk9TDEWGZird {
  margin-top: 30px;
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: space-between;
}

.spHpVEebzyfODF4jiLYp {
  width: 100%;
}

.cdgW8yb1u4rBs7YCPBaw {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/events/modify/CheckInDetails/CheckInDetails.module.sass"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,eAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,aAAA;EACA,WAAA;EACA,SAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ","sourcesContent":["\n.error\n    color: #e70000\n    font-size: 12px\n\n\n.wrapper\n    margin-top: 30px\n    display: flex\n    width:100%\n    gap:30px\n    justify-content: space-between\n\n\n.date\n    width:100%\n\n\n.hidden\n    display: none"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `NMX9JEbdSQjpCIXekFjF`,
	"wrapper": `DP5rDw7Vkk9TDEWGZird`,
	"date": `spHpVEebzyfODF4jiLYp`,
	"hidden": `cdgW8yb1u4rBs7YCPBaw`
};
export default ___CSS_LOADER_EXPORT___;
