import cn from 'classnames';

import styles from './Row.module.sass';
import { IEventTicketListItem } from '@types';
import Icon from 'components/Icon';
import { TicketRequestStatus } from '@core';

interface RowProps {
  item: IEventTicketListItem;
  onDecline: (id: string) => void;
  onApprove: (id: IEventTicketListItem) => void;
}

const Row = ({ item, onDecline, onApprove }: RowProps) => {
  const approveTicket = () => {
    onApprove(item);
  };

  const handleDeclineTicket = async (id: string) => {
    const confirmed = window.confirm('ნამდვილად გსურთ უარი?');
    if (!confirmed) {
      return;
    }
    onDecline(id);
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.avatar}>
              <img
                src={item.user.profilePhoto !== '' ? item.user.profilePhoto : '/images/content/avatar-2.jpg'}
                alt="Profile Photo"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{`${item.user.firstName} ${item.user.lastName}`}</div>
              <div className={styles.city}>{item.user.phoneNumber}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div>
            {item.quantity}{' '}
            {item.additionalUsers && item.additionalUsers.length > 0 && (
              <span>
                (
                {item.additionalUsers.map((name, index) => (
                  <span key={index}>, {name}</span>
                ))}
                )
              </span>
            )}
          </div>
        </div>

        <div className={styles.col}>
          <div>{item.updatedAt}</div>
        </div>

        <div className={styles.col}>
          <div>
            {item.status === TicketRequestStatus.Approved && (
              <div className={cn('status-green', styles.status)}>დადასტურებულია</div>
            )}

            {item.status === TicketRequestStatus.Pending && (
              <div className={cn('status-yellow', styles.status)}>მოთხოვნილი</div>
            )}

            {item.status === TicketRequestStatus.Declined && (
              <div className={cn('status-red', styles.status)}>უარყოფილია</div>
            )}
          </div>
        </div>

        <div className={styles.col}>
          {item.status !== TicketRequestStatus.Approved && (
            <button
              onClick={() => approveTicket(item.id)}
              className={cn('button', 'button-small', 'button-stroke-green', styles.button)}
            >
              <Icon name="check" size="24" />
            </button>
          )}

          {item.status !== TicketRequestStatus.Declined && (
            <button
              onClick={() => void handleDeclineTicket(item.id)}
              className={cn('button', 'button-small', 'button-stroke-red', styles.button)}
            >
              <Icon name="close" size="24" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Row;
