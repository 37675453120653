// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qdIpguJkZdrqMTUVG82j {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.gBEipxrdwflVVYP3v0sb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FJjwO9nT7WJxlUC8mk8A {
  position: relative;
  z-index: 1001;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.sass"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AACA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,kBAAA;EACA,aAAA;EACA,WAAA;AAEJ","sourcesContent":[".wrapper\n    width: 100%\n    display: flex\n    justify-content: center\n    padding: 20px\n\n.overlay\n    position: fixed\n    top: 0\n    left: 0\n    width: 100%\n    height: 100%\n    background-color: rgba(0, 0, 0, 0.5)\n    z-index: 1000\n    display: flex\n    justify-content: center\n    align-items: center\n\n\n.content\n    position: relative\n    z-index: 1001\n    width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `qdIpguJkZdrqMTUVG82j`,
	"overlay": `gBEipxrdwflVVYP3v0sb`,
	"content": `FJjwO9nT7WJxlUC8mk8A`
};
export default ___CSS_LOADER_EXPORT___;
