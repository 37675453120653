import { createSelector } from '@reduxjs/toolkit';

import { UiSpinnerFeatureKey } from 'core';
import { RootState, UIState } from 'types';
import { uiFeature } from './ui.slice';

export const uiSelector = (state: RootState): UIState => state[uiFeature];

export const uiGlobalSpinnerSelector = createSelector(
  uiSelector,
  uiState => uiState.spinner[UiSpinnerFeatureKey.Global],
);

export const languageSelector = createSelector(uiSelector, uiState => uiState.language);
