import { CaseReducer, PayloadAction, createSlice, createAction } from '@reduxjs/toolkit';

import { Locale, UiSpinnerFeatureKey } from '@core';
import { UIState } from '@types';

type SpinnerActionPayload = { feature: UiSpinnerFeatureKey; state: boolean };

const name = 'ui';
const initialState = {
  spinner: {
    [UiSpinnerFeatureKey.Global]: true,
  },
  language: Locale.En,
} as UIState;

const init = createAction(`${name}/init`);
const setGlobalLoader = createAction<boolean>(`${name}/setGlobalLoader`);
const changeLanguage = createAction<Locale>(`${name}/changeLanguage`);

const setGlobalSpinnerReducer: CaseReducer<UIState, PayloadAction<SpinnerActionPayload>> = (state, { payload }) => {
  state.spinner[payload.feature] = payload.state;
};

const changeLanguageReducer: CaseReducer<UIState, PayloadAction<{ lang: Locale }>> = (state, { payload }) => {
  state.language = payload.lang;
};

export const uiSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFeatureSpinnerStateStore: setGlobalSpinnerReducer,
    changeLanguageStateStore: changeLanguageReducer,
  },
});

export const uiFeature = name;
export const uiActions = {
  ...uiSlice.actions,
  setGlobalLoader,
  changeLanguage,
  init,
};
