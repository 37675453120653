import { Dispatch, MutableRefObject, useEffect } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

import { Action, DrawResult, DrawingActionKind, Snapshot, State, isPolygon } from './types';

export default function reducer(state: State, action: Action) {
  switch (action.type) {
    case DrawingActionKind.UPDATE_OVERLAYS: {
      const overlay = state.now;

      if (!overlay) {
        return state;
      }

      const { geometry } = overlay;

      if (isPolygon(geometry)) {
        overlay.snapshot.path = geometry.getPath()?.getArray();
      }

      return {
        now: { ...overlay },
      };
    }

    case DrawingActionKind.SET_OVERLAY: {
      const { overlay } = action.payload;

      const snapshot: Snapshot = {};

      if (isPolygon(overlay)) {
        const path = overlay.getPath()?.getArray();
        snapshot.path = path;
      }

      return {
        now: {
          type: action.payload.type,
          geometry: action.payload.overlay,
          snapshot,
        },
      };
    }
    default:
      return { ...state };
  }
}

// Handle drawing manager events
export function useDrawingManagerEvents(
  drawingManager: google.maps.drawing.DrawingManager | null,
  overlaysShouldUpdateRef: MutableRefObject<boolean>,
  dispatch: Dispatch<Action>,
  savedCoordinates: string | null,
) {
  const map = useMap();

  const eventListeners: Array<google.maps.MapsEventListener> = [];

  const addUpdateListener = (eventName: string, drawResult: DrawResult) => {
    const updateListener = google.maps.event.addListener(drawResult.overlay, eventName, () => {
      if (eventName === 'dragstart') {
        overlaysShouldUpdateRef.current = false;
      }

      if (eventName === 'dragend') {
        overlaysShouldUpdateRef.current = true;
      }

      if (overlaysShouldUpdateRef.current) {
        dispatch({ type: DrawingActionKind.UPDATE_OVERLAYS });
      }
    });

    eventListeners.push(updateListener);
  };

  useEffect(() => {
    if (!drawingManager) return;

    const overlayCompleteListener = google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (drawResult: DrawResult) => {
        switch (drawResult.type) {
          case google.maps.drawing.OverlayType.POLYGON:
          case google.maps.drawing.OverlayType.POLYLINE:
            addUpdateListener('mouseup', drawResult);
        }

        dispatch({ type: DrawingActionKind.SET_OVERLAY, payload: drawResult });
      },
    );

    eventListeners.push(overlayCompleteListener);

    return () => {
      eventListeners.forEach(listener => google.maps.event.removeListener(listener));
    };
  }, [dispatch, drawingManager, overlaysShouldUpdateRef]);

  useEffect(() => {
    if (!map || !drawingManager) return;

    if (savedCoordinates) {
      const coordinates = JSON.parse(savedCoordinates) as { lat: number; lng: number }[] | null;

      if (coordinates) {
        const polygon = new google.maps.Polygon({
          paths: coordinates,
          map: map,
          editable: true,
          draggable: true,
        });

        const drawResult = {
          type: google.maps.drawing.OverlayType.POLYGON,
          overlay: polygon,
        };

        addUpdateListener('mouseup', drawResult);

        dispatch({
          type: DrawingActionKind.SET_OVERLAY,
          payload: drawResult,
        });
      }
    }
  }, [map, drawingManager, savedCoordinates]);

  useEffect(() => {
    return () => {
      eventListeners.forEach(listener => google.maps.event.removeListener(listener));
    };
  }, []);
}

// Update overlays with the current "snapshot" when the "now" state changes
export function useOverlaySnapshots(
  map: google.maps.Map | null,
  state: State,
  overlaysShouldUpdateRef: MutableRefObject<boolean>,
) {
  useEffect(() => {
    if (!map || !state.now) return;

    const overlay = state.now;
    if (overlay) {
      overlaysShouldUpdateRef.current = false;

      overlay.geometry.setMap(map);

      const { path } = overlay.snapshot;

      if (isPolygon(overlay.geometry)) {
        overlay.geometry.setPath(path ?? []);
      }

      overlaysShouldUpdateRef.current = true;
    }

    return () => {
      const current = state.now;
      if (current) {
        current.geometry.setMap(null);
      }
    };
  }, [map, overlaysShouldUpdateRef, state.now]);
}
