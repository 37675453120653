import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Item.module.sass';
import { NotificationListItem } from '@types';

interface ItemProps {
  className?: string;
  item: NotificationListItem;
}

const Item: React.FC<ItemProps> = ({ className, item }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.avatar}>
        <img src={item.from.profilePhoto} alt="Avatar" />
        <div className={styles.icon} style={{ backgroundColor: '#2A85FF' }}>
          <img src="/images/content/message.svg" alt="Status" />
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>
            გამგზავნი - @{item.from.firstName} {item.from.lastName}
          </div>
          <div className={styles.time}>{item.createdAt}</div>
        </div>
        <div className={styles.type}>ტიპი : {t(item.type)}</div>
        <div className={styles.comment}>სათაური: {item.title}</div>
        <br />
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.message }}></div>
        {/* {item.properties && <div className={styles.properties}>დამატებითი ინფორმაცია: {item.properties}</div>} */}
      </div>
    </div>
  );
};

export default Item;
